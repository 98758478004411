<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t('solution33')}}</h1>
          <h2>{{$t('solutionDdos')}}</h2>
          <p class="sm">{{$t('solution34')}}</p>
          <el-button type="primary">{{$t('getPlan')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution35')}}</h2>
        <ul class="list1">
          <li class="icon13">
            <h5>{{$t('solution36')}}</h5>
            <p>{{$t('solution37')}}<br/>{{$t('solution38')}}</p> 
          </li>
          <li class="icon54">
            <h5>{{$t('solution39')}}</h5>
            <p>{{$t('solution40')}}</p> 
          </li>
          <li class="icon55">
            <h5>{{$t('solution41')}}</h5>
            <p>{{$t('solution42')}}</p>  
          </li>
          <li class="icon46">
            <h5>{{$t('solution43')}}</h5>
            <p>{{$t('solution44')}}</p>  
          </li>
          <li class="icon55">
            <h5>{{$t('solution45')}}</h5>
            <p>{{$t('solution46')}}</p>  
          </li>
          <li class="icon27">
            <h5>{{$t('solution47')}}</h5>
            <p>{{$t('solution48')}}</p>  
          </li>
        </ul>
        <h2>{{$t('solution49')}}</h2>
        <ul class="list1">
          <li class="icon1">
            <h5>{{$t('productSdk')}}</h5>
            <p>{{$t('solution50')}} </p> 
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon2">
            <h5>{{$t('productScdn')}}</h5>
            <p>{{$t('solution51')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon3">
            <h5>{{$t('home36')}}</h5>
            <p>{{$t('home37')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image19.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution52')}}</h4>
              <p>{{$t('solution53')}}
</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image20.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution54')}}</h4>
              <p>{{$t('solution55')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image21.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution56')}}</h4>
              <p>{{$t('solution57')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image9.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution58')}}</h4>
              <p>{{$t('solution59')}}</p>
            </div>
          </li>
        </ul>
        <hr/>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionDdos",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('solutionDdos')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }

  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
